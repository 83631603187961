import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { CannedResponseTemplate } from "app-types/template";
import PlainHeader from "components/ui/base/PlainHeader";
import { CANNED_RESPONSE_TEMPLATES_PATH } from "constants/paths";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import CannedResponseTemplates from "components/ui/extended/CannedResponseTemplates";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

interface CannedResponsePageProps {
  data: {
    allCannedResponsesTemplates: {
      nodes: CannedResponseTemplate[];
    };
  };
  location: Location;
}

export default ({ data, location }: CannedResponsePageProps) => {
  const { t } = useTranslation();

  const cannedResponseTemplates = data.allCannedResponsesTemplates.nodes;

  const totalTemplates = cannedResponseTemplates.length;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.canned-response-templates.seo.title")}
        description={t("pages.canned-response-templates.seo.description")}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: t("pages.canned-response-templates.seo.title"),
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.canned-response-templates.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.canned-response-templates.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />
      <PlainHeader
        title={t("pages.canned-response-templates.title")}
        description={t("pages.canned-response-templates.description", {
          count: totalTemplates,
        })}
        maxWidth={900}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Templates",
            path: "/templates/",
          },
          {
            name: "Canned Response Templates",
            path: `${CANNED_RESPONSE_TEMPLATES_PATH}/`,
          },
        ]}
      />

      <CannedResponseTemplates templates={cannedResponseTemplates} />

      <QuestionsAndAnswers
        title={t("pages.canned-response-templates.faq.title")}
        description={t("pages.canned-response-templates.faq.description")}
        elements={[
          {
            question: t("pages.canned-response-templates.faq.1.question"),
            answer: t("pages.canned-response-templates.faq.1.answer"),
          },
          {
            question: t("pages.canned-response-templates.faq.2.question"),
            answer: t("pages.canned-response-templates.faq.2.answer"),
          },
          {
            question: t("pages.canned-response-templates.faq.3.question"),
            answer: t("pages.canned-response-templates.faq.3.answer"),
          },
        ]}
      />

      <RegisterForCustomerService />

      <Customers />

      <BlogPostsBox
        category={"customer-service"}
        title={"Customer Service Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};
export const templatesPageQuery = graphql`
  query {
    allCannedResponsesTemplates {
      nodes {
        canned_response_template_id
        emoji
        description
        background
        canned_response {
          is_shared
          shortcut
          text
          title
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;
